/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)

import './styles/swiss-safe-solutions.scss';
// start the Stimulus application
import './bootstrap'; 

 
require('bootstrap');  
//const $ = require('jquery');   
   
// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');  

$(window).scroll(function(){
    if ($(this).scrollTop() > 120) {
        $('.navbar-custom-nav').addClass('fixed');
    } else {
        $('.navbar-custom-nav').removeClass('fixed');  
    }
  
       
});   


 

  
 
     

$(document).ready(function(){
    $('.blue-point').on('mouseenter',function(){
        $(this).parent().find('.blue-point-hover').fadeIn();
    })
    $('.blue-point').on('mouseout',function(){
        $(this).parent().find('.blue-point-hover').fadeOut();
    });

    $(document).on('click','.kontakt-close',function(e){
        if($(window).width()<=991){
            $('.navbar-collapse.collapse').removeClass('show')
        }
    });

    $(document).on('click','.mobile-burger',function(e){
        e.stopPropagation();
        $(this).toggleClass('checked');
        $('#navigation').fadeToggle();
    });

    $(document).on('click','.top-navigation',function(e){
        e.stopPropagation();
    })

    $(document).on('click','html',function(e){
        if($(window).width()<=1000){
            $('.mobile-burger').removeClass('checked');
            $('#navigation').fadeOut();
        }
    })

   
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })  
    

    $('.center').slick({
        centerMode: true,        
        centerPadding: '15',    
        slidesToShow: 3, 
        // speed: 500,  
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '0px', 
              slidesToShow: 1       
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 1
            }
          }
        ]
      });   


      new WOW().init();  

})

$(function () { 
  var fx = function fx() {
  $(".stat-number").each(function (i, el) {
      var data = parseInt(this.dataset.n, 10);
      var props = {
          "from": {
              "count": 0
          },
              "to": { 
              "count": data
          }
      };
      $(props.from).animate(props.to, {
          duration: 1500 * 1, 
          step: function (now, fx) {
              $(el).text(Math.ceil(now));
          },
          complete:function() {
              if (el.dataset.sym !== undefined) {
                el.textContent = el.textContent.concat(el.dataset.sym)
              }
          }
      });
  });
  };
  
  var reset = function reset() {
      //console.log($(this).scrollTop())
      if ($(this).scrollTop() > 90) {
        //   $(this).off("scroll");
        // fx()
      }
  };
  $(window).scroll(function() {
      /*
    var hT = $('#home-section-6').offset().top,
        hH = $('#home-section-6').outerHeight(),
        wH = $(window).height(),
        wS = $(this).scrollTop();
     console.log((hT-wH) , wS);
    if (wS > (hT+hH-wH)){
      $(this).off("scroll");
        fx();
      //alert('you have scrolled to the h1!');
    }
       */
 });

  //$(window).on("scroll", reset);
});